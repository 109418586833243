<template>
  <AllAssets :asset_type="asset_type" :subHeaderTitle="subHeaderTitle" :subHeaderSubtitle="subHeaderSubtitle"
    :image="image" />
</template>

<script>
import AllAssets from '../components/AllAssets.vue'

export default {
  components: { AllAssets },
  data() {
    return {
      asset_type: 'Success Story',
      subHeaderTitle: "Success Stories",
      subHeaderSubtitle: "Discover Assets spplication in real world use cases",
      image: "looking-at-charts.svg"
    }
  }
}
</script>