import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import AssetsPage from "../views/AssetsPage.vue";
import SuccessStories from "../views/SuccessStories.vue";
import TrainingResources from "../views/TrainingResources.vue";
import AddAsset from "../views/AddAsset.vue";
import AddProject from "../views/AddProject.vue";
import AssetDetails from "../views/AssetDetails.vue";
import ProjectDetails from "../views/ProjectDetails.vue";
import UserDetails from "../views/UserDetails.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/assets",
    name: "all-assets",
    component: AssetsPage,
  },
  {
    path: "/success-stories",
    name: "success-stories",
    component: SuccessStories,
  },
  {
    path: "/training-resources",
    name: "training-resources",
    component: TrainingResources,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/add-asset",
    name: "add-asset",
    component: AddAsset,
  },
  {
    path: "/add-project",
    name: "add-project",
    component: AddProject,
  },
  {
    path: "/asset/:id",
    name: "asset-details",
    component: AssetDetails,
    props: true,
  },
  {
    path: "/project/:id",
    name: "project-details",
    component: ProjectDetails,
    props: true,
  },
  {
    path: "/user/:id",
    name: "user-details",
    component: UserDetails,
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  //catch 404
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

import { useAuthStore } from "../stores/authStore";

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/", "/about"];
  const authRequired = !publicPages.includes(to.path);
  const auth = localStorage.getItem('access_token')


  if (authRequired && !auth) {
    if (to.fullPath == "/logout") {
    auth.returnUrl = "/";
    } else {
    auth.returnUrl = to.fullPath;
    }
    return "/login";
  }
});

export default router;
