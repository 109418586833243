<template>
        <!-- sub header -->
        <div class="bg-primary d-flex align-items-center" style="height: 20rem">
          <div class="container-xxl d-flex flex-row justify-content-around">
            <div class="d-flex align-items-center">
              <div class="text-white d-flex flex-column">
                <h1>{{ title }}</h1>
                <h5>{{ subtitle }}</h5>
              </div>
            </div>

            <div>
              <img v-bind:src="require(`../assets/img/${image}`)"
                              style="max-width: 20rem; height: 100%" />
            </div>
          </div>
        </div>
        <!-- end sub header -->
</template>

<script>

export default {
    props: ['title', 'subtitle', 'image'],

}
</script>