<template>
    <div class="container-xxl mt-4 content">
        <ProjectForm />
    </div>
</template>

<script>
import ProjectForm from '../components/ProjectForm.vue'

export default {
    components: { ProjectForm },
}
</script>
