<template>

    <!-- assets cards -->
    
        <div class="row row-cols-2 row-cols-sm-2 row-cols-lg-3 g-2 g-lg-4 d-flex">

            <div v-if="!assets">
                <h2>No asset available</h2>
            </div>
            <div v-for="asset in assets" :key="asset">
                <CardCollapsed :asset="asset" />
            </div>
        </div>


</template>

<script>

import CardCollapsed from './CardCollapsed.vue';

export default {
    components: { CardCollapsed },
    props: ['assets']
}
</script>