<template>
  <div class="container-xxl mt-4 content">
    <SingleCard :asset="asset" :detail="detail"/>
  </div>

</template>

<script>
import axios from 'axios'
import SingleCard from '../components/SingleCard.vue'

export default {
  components: { SingleCard },
  props: ['id'],
  created(){
    this.getAsset(this.id)
  },
  data(){
    return {
      asset: {},
      detail: true
    }
  },
  methods: {
    async getAsset(asset_id){
      const url = 'asset/' + asset_id
      await axios.get(url)
      .then( res => res.data)
      .then(data => this.asset = data)
      .catch(err => console.log(err))
    }
  } 
}

</script>