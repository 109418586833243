import router from "@/router";
import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      admin: localStorage.getItem('admin'),
      access_token: localStorage.getItem('access_token'),
      returnUrl: '/',
    };
  },
  actions: {
    async login(body) {
      const url = "user/login";

      await axios
        .post(url, body)
        .then((res) => {
          this.user = res.data;
          localStorage.setItem("user", JSON.stringify(res.data));
          this.admin = this.user.admin;
          localStorage.setItem("admin", this.user.admin);
          this.access_token = this.user.access_token;
          localStorage.setItem("access_token", this.access_token);
        })
        .then(() => router.push(this.returnUrl || "/"))
        .catch((err) => {
          console.log(err);
          alert("wrong credentials");
        });
    },
    async logout() {
      this.user = null;
      localStorage.removeItem("user");
      this.admin = false;
      localStorage.removeItem("admin");
      this.access_token = null;
      localStorage.removeItem("access_token");
      router.push("/login");
    },
  },
});

