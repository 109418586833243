<template>
  <div class="container-xxl mt-4 content">
      <LoginForm />
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'

export default {
  name: 'login',
  components: { LoginForm }
}

</script>