<template>
    <form @submit.prevent="submitForm">
        <fieldset>

            <div class="form-group">
                <label for="assetName" class="form-label mt-4">Asset name</label>
                <input type="text" class="form-control" id="assetName" placeholder="Enter Asset Name" v-model="asset.name">
                <div class="invalid-feedback">Please enter an asset name.</div>
            </div>

            <div class="form-group">
                <label for="short_description" class="form-label mt-4">Short description</label>
                <textarea class="form-control" id="short_description" rows="3" v-model="asset.short_description"></textarea>
            </div>

            <div class="form-group">
                <label for="extended_description" class="form-label mt-4">Extended description</label>
                <textarea class="form-control" id="extended_description" rows="3"
                    v-model="asset.extended_description"></textarea>
            </div>

            <div class="form-group">
                <label for="type" class="form-label mt-4">Asset Type</label>
                <select class="form-select" id="type" @change="(e) => asset.type = e.target.value">
                    <option value="" selected disabled>Select an Asset Type</option>
                    <option value="Asset">Asset</option>
                    <option value="Success Story">Success Story</option>
                    <option value="Training">Training</option>
                </select>
            </div>

            <div class="form-group">
                <label for="owner" class="form-label mt-4">Owner</label>
                <input type="text" class="form-control" id="owner" placeholder="Enter Owner" v-model="asset.owner">
            </div>

            <div class="form-group">
                <label for="type" class="form-label mt-4">Project</label>
                <select class="form-select" id="type" @change="(e) => asset.project = JSON.parse(e.target.value)">
                    <option value="" selected disabled>Select the related Project</option>
                    <option v-for="project in projects" :key="project" :value="JSON.stringify(project)">{{project.name}}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="category" class="form-label mt-4">Category</label>
                <input type="text" class="form-control" id="category" placeholder="Enter category" v-model="asset.category">
            </div>

            <div class="form-group">
                <label for="release_date" class="form-label mt-4">Release Date</label>
                <input type="text" class="form-control" id="release_date" placeholder="Enter Release Date"
                    v-model="asset.release_date">
            </div>

            <div class="form-group">
                <label for="field_of_use" class="form-label mt-4">Field of Use</label>
                <input type="text" class="form-control" id="field_of_use" placeholder="Enter Field of Use"
                    v-model="asset.field_of_use">
            </div>

            <div class="form-group">
                <label for="license" class="form-label mt-4">License</label>
                <input type="text" class="form-control" id="license" placeholder="Enter License" v-model="asset.license">
            </div>

            <div class="form-group">
                <label for="license_link" class="form-label mt-4">License Link</label>
                <input type="text" class="form-control" id="license_link" placeholder="Enter License Link"
                    v-model="asset.license_link">
            </div>

            <div class="form-group">
                <label for="keywords" class="form-label mt-4">Keywords</label>
                <input type="text" class="form-control" id="keywords" aria-describedby="keywordsHelp"
                    placeholder="Enter Keywords" v-model="tempKeyword" @keyup.alt="addKeyword">
                <small id="keywordsHelp" class="form-text text-muted">To enter a keyword press "comma + alt". Do delete a
                    keyword click twice on it.</small>
                <div class="d-flex align-items-center">
                    <div class="me-1" v-for="keyword in asset.keywords" :key="keyword">
                        <span class="badge rounded-pill bg-info me-auto" style="cursor: pointer;"
                            @dblclick="deeleteKeyword(keyword)">{{ keyword }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="asset_webpage_link" class="form-label mt-4">Asset Webpage Link</label>
                <input type="text" class="form-control" id="asset_webpage_link" placeholder="Enter Asset Webpage Link"
                    v-model="asset.asset_webpage_link">
            </div>

            <div class="form-group">
                <label for="gitlab_link" class="form-label mt-4">GitLab Link</label>
                <input type="text" class="form-control" id="gitlab_link" placeholder="Enter GitLab Link"
                    v-model="asset.gitlab_link">
            </div>

        </fieldset>
        <button type="submit" class="btn btn-primary mt-4 mb-4">Submit</button>
    </form>
</template>

<script>
import axios from 'axios'
export default {
      created() {
        this.getProjects()
    },
    data() {
        return {
            asset: {},
            tempKeyword: '',
            projects: []
        }
    },
    methods: {
        addKeyword(e) {
            if (e.key === ',' && this.tempKeyword) {

                if (!('keywords' in this.asset)) {
                    this.asset.keywords = []
                }
                if (!this.asset.keywords.includes(this.tempKeyword)) {
                    this.asset.keywords.push(this.tempKeyword)
                    this.tempKeyword = ''
                }
            }
        },
        deeleteKeyword(keyword) {
            this.asset.keywords.pop(keyword)
        },
        async submitForm() {
            const asset_data = (JSON.parse(JSON.stringify(this.asset)))
            console.log(asset_data)
            const url = 'asset'
            await axios.post(url, asset_data)
                .then(res => res.data._id)
                .then(id => this.$router.push({ name: 'asset-details', params: { id: id } }))
                .catch(err => console.log(err))
        },
        async getProjects(){
            const url = 'project'
            await axios.get(url)
            .then(res => res.data)
            .then(data => this.projects = data)
            .catch(err => console.log(err))
        }
    }

}

</script>