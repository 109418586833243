<template>
    <div class="container-xxl mt-4 content">
        <AssetForm />
    </div>
</template>

<script>
import AssetForm from '../components/AssetForm.vue'

export default {
    name: 'App',
    components: { AssetForm },
}
</script>
