<template>
    <div class="col" style="height: 100%;">
        <div class="card mb-2">

            <div class="card-body">
                <div class="row" style="height: 100%;">
                    <!-- title and brief description -->
                    <div class="col-8 d-flex">
                        <h4> <b>{{ project.name }}</b> </h4>
                    </div>
                </div>


                <div class="row mt-2">
                    <div class="col d-flex ">
                        <p v-if="project.description">{{ project.description }}</p>
                    </div>
                </div>



                <div v-if="project.webpage_link">
                    <hr>
                    <div class="row">
                        <div class="col-sm-5 d-flex ">
                            <h6 class="">Webpage link</h6>
                        </div>
                        <div class="col-sm-7 d-flex ">
                            <a href="#" class="link-primary">{{ project.webpage_link }}</a>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script>

export default {
    props: ['project']
}

</script>