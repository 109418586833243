<template>
  <AllAssets :asset_type="asset_type" 
              :subHeaderTitle="subHeaderTitle" 
              :subHeaderSubtitle="subHeaderSubtitle"
              :image="image"/>
</template>

<script>
import AllAssets from '../components/AllAssets.vue'

export default {
  components: { AllAssets},
  data() {
    return {
      asset_type: 'Asset',
      subHeaderTitle: "Assets Discovery",
      subHeaderSubtitle: "Discover technological components",
      image: "search.svg"
    }
  }
}
</script>
