<template>
  <div class="content">


    <div class="container-xxl mt-4">
      <div class="d-flex flex-row align-item-center">
        <div class="d-flex flex-column m-4">
          <h3>Welcome to</h3>
          <br>
          <h1><b>Innovation Marketplace</b></h1>
        </div>
      </div>
    </div>

    <div class="container-xxl mt-4">
      <div class="d-flex justify-content-end">
        <img v-bind:src="require(`../assets/img/factory.svg`)" style="max-width: 25rem; height: 100%" />
      </div>
    </div>
    <div>


      <div class="d-flex flex-row bg-primary ">
        <div class="d-flex flex-row container-xxl mt-4">

          <div class="col m-4 p-3 text-white text-center">
            <h3><b>Assets</b></h3>
            <h1 class="nbr">{{ assetsNumber }}</h1>
          </div>

          <div class="col m-4 p-3 text-white  text-center">
            <h3><b>Success Stories</b></h3>
            <h1 class="nbr">{{ successNumber }}</h1>
          </div>

          <div class="col m-4 p-3 text-white  text-center">
            <h3><b>Training</b></h3>
            <h1 class="nbr">{{ trainingNumber }}</h1>
          </div>

          <div class="col m-4 p-3 text-white  text-center">
            <h3><b>Projects</b></h3>
            <h1 class="nbr">{{ projectsNumber }}</h1>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- <div class="d-flex flex-row">
        <div class="col m-4 p-3 bg-secondary bg-opacity-10 border border-primary rounded text-center">

          <h3><b>Assets</b></h3>
          <h1 class="nbr">{{ assetsNumber }}</h1>

        </div>
        <div class="col m-4 p-3 bg-secondary bg-opacity-10 border border-primary rounded text-center">
          <h3><b>Success Stories</b></h3>
          <h1 class="nbr">{{ successNumber }}</h1>

        </div>
        <div class="col m-4 p-3 bg-secondary bg-opacity-10 border border-primary rounded text-center">
          <h3><b>Training</b></h3>
          <h1 class="nbr">{{ trainingNumber }}</h1>

        </div>
      </div> -->
</template>

<script>
import axios from 'axios'

export default {
  created() {
    this.getAssetsNumber('Asset').then(data => this.assetsNumber = data)
    this.getAssetsNumber('Success Story').then(data => this.successNumber = data)
    this.getAssetsNumber('Training').then(data => this.trainingNumber = data)
    this.countProjects()
  },
  data() {
    return {
      assetsNumber: 0,
      successNumber: 0,
      trainingNumber: 0,
      projectsNumber: 0
    }
  },
  methods: {
    async getAssetsNumber(asset_type) {
      const url = 'asset/list/number-of'

      return await axios.get(url, {
        params: {
          asset_type: asset_type
        }, paramsSerializer: {
          indexes: null
        }
      })
        .then(res => res.data)
        .catch(err => console.log(err))
    },
    async countProjects() {
      const url = '/project/list/number-of'
      await axios.get(url)
        .then(res => res.data)
        .then(data => this.projectsNumber = data)
        .catch(err => console.log(err))
    }
  }
}
</script>