<template>
    <form action="">

        <div class="accordion" id="accordionSearchOptions">
            <div class="accordion-item list-group">
                <h2 class="accordion-header" id="categoriesOptions-heading">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#categoriesOptions-collapse" aria-expanded="false"
                        aria-controls="categoriesOptions-collapse">
                        <b>Categories</b>
                    </button>
                </h2>
                <div id="categoriesOptions-collapse" class="accordion-collapse collapse"
                    aria-labelledby="categoriesOptions-heading">
                     <span type="button"
                    v-bind:class="(categoryQuery.length == 0) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'"
                    aria-current="true" @click="selectAllCategories($event)">
                    All
                </span>
                <span v-for="category in categories" :key="category" class="list-group-item list-group-item-action category"
                    @click="addToCategoryQuery($event, category)">
                    {{ category }}</span>
                </div>
                
            </div>

            <div class="accordion-item list-group">
                <h2 class="accordion-header" id="fieldsOfUseOptions-heading">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#fieldsOfUseOptions-collapse" aria-expanded="false"
                        aria-controls="fieldsOfUseOptions-collapse">
                        <b>Fields of Use</b>
                    </button>
                </h2>
                <div id="fieldsOfUseOptions-collapse" class="accordion-collapse collapse"
                    aria-labelledby="fieldsOfUseOptions-heading">
                    <span type="button"
                    v-bind:class="(fieldQuery.length == 0) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'"
                    aria-current="true" @click="selectAllFields($event)">
                    All
                </span>
                <span v-for="field in fields_of_use" :key="field" class="list-group-item list-group-item-action field"
                    @click="addToFieldQuery($event, field)">
                    {{ field }}</span>
                </div>
            </div>

             <div class="accordion-item list-group">
                    <h2 class="accordion-header" id="projectOptions-heading">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#projectOptions-collapse" aria-expanded="false"
                            aria-controls="projectOptions-collapse">
                            <b>Project</b>
                        </button>
                    </h2>
                    <div id="projectOptions-collapse" class="accordion-collapse collapse"
                        aria-labelledby="projectOptions-heading">
                        <span type="button"
                        v-bind:class="(projectQuery.length == 0) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'"
                        aria-current="true" @click="selectAllProjects($event)">
                        All
                    </span>
                    <span v-for="project in projects" :key="project" class="list-group-item list-group-item-action project"
                        @click="addToProjectQuery($event, project._id)">
                        {{ project.name }}</span>
                    </div>
                </div>

        </div>

        <!-- <fieldset>
            <span class="btn btn-primary mt-4" @click="searchAll">Search</span>
        </fieldset> -->
    </form>
</template>

<script>
import axios from 'axios'

export default {
    created() {
        this.getCategories('category').then(data => this.categories = data)
        this.getCategories('field_of_use').then(data => this.fields_of_use = data)
        this.getProjects()
    },
    data() {
        return {
            categories: [],
            fields_of_use: [],
            projects:[],
            categoryQuery: this.categoryQuery,
            fieldQuery: this.fieldQuery,
            projectQuery: this.projectQuery
        }
    },
    props: ['asset_type', 'categoryQuery', 'fieldQuery', 'keywordsQuery', 'projectQuery'],
    methods: {
        async getCategories(parameter) {
            const url = '/asset/categories'
            return await axios.get(url, { params: { asset_type: this.asset_type, parameter: parameter } })
                .then(res => res.data)
                .catch(err => console.log(err))
        },
        async getProjects(){
            const url = 'project'
            await axios.get(url)
            .then(res => res.data)
            .then(data => this.projects = data)
            .catch(err => console.log(err))
        },
        addToCategoryQuery(e, category) {
            e.target.classList.toggle('active')
            if (!this.categoryQuery.includes(category)) {
                this.categoryQuery.push(category);

            } else {
                const index = this.categoryQuery.indexOf(category);
                this.categoryQuery.splice(index, 1);
            }
            this.$emit("update-categoryQuery", this.categoryQuery)
        },
        selectAllCategories(e) {
            e.target.classList.add('active')
            const categoryOptions = document.querySelectorAll(".category")
            for (var i = 0; i < categoryOptions.length; i++) {
                let catOption = categoryOptions[i]
                catOption.classList.remove('active')
            }
            this.$emit("update-categoryQuery", [])
        },
        addToFieldQuery(e, field) {
            e.target.classList.toggle('active')
            if (!this.fieldQuery.includes(field)) {
                this.fieldQuery.push(field);

            } else {
                const index = this.fieldQuery.indexOf(field);
                this.fieldQuery.splice(index, 1);
            }
            this.$emit("update-fieldQuery", this.fieldQuery)
        },
        selectAllFields(e) {
            e.target.classList.add('active')
            const fieldOptions = document.querySelectorAll(".field")
            for (var i = 0; i < fieldOptions.length; i++) {
                let fiOption = fieldOptions[i]
                fiOption.classList.remove('active')
            }
            this.$emit("update-fieldQuery", [])
        },
        addToProjectQuery(e, project_id){
            e.target.classList.toggle('active')
            if (!this.projectQuery.includes(project_id)) {
                this.projectQuery.push(project_id);
            } else {
                const index = this.projectQuery.indexOf(project_id);
                this.projectQuery.splice(index, 1);
            }
            this.$emit("update-projectQuery", this.projectQuery)
        },
        selectAllProjects(e){
            e.target.classList.add('active')
            const projects = document.querySelectorAll(".project")
            for (var i = 0; i < projects.length; i++) {
                let project = projects[i]
                project.classList.remove('active')
            }
            this.$emit("update-projectQuery", [])
        }

    }
}

</script>