<template>
  <SubHeader :title="subHeaderTitle" :subtitle="subHeaderSubtitle" :image="image" />

  <div class="container-xxl d-flex flex-row content">
    <div class="col-2 m-3 mt-4">
      <FilterDiv :asset_type="asset_type" :fieldQuery="fieldQuery" @update-fieldQuery="onUpdateFieldQuery"
        :categoryQuery="categoryQuery" @update-categoryQuery="onUpdateCategoryQuery" :keywordsQuery="keywordsQuery" 
        :projectQuery="projectQuery"  @update-projectQuery="onUpdateProjectQuery"/>
    </div>
    <div class="col m-3 mt-4">
      <CardsList :assets="assets" />

    </div>
  </div>
</template>

<script>
import CardsList from '../components/CardsList.vue'
import SubHeader from '../components/SubHeader.vue'
import FilterDiv from '../components/FilterDiv.vue'
import axios from 'axios'


export default {
  name: 'App',
  created() {
    this.getAllAssets(this.fieldQuery, this.categoryQuery, this.projectQuery)
  },
  components: { CardsList, SubHeader, FilterDiv },
  data() {
    return {
      assets: [],
      fieldQuery: [],
      categoryQuery: [],
      projectQuery: [],
      keywordsQuery: []
    }
  },
  props: ['asset_type', 'subHeaderTitle', 'subHeaderSubtitle', 'image', 'asset_type' ],
  methods: {
    async getAllAssets(fieldQuery = null, categoryQuery = null, projectQuery= null, keywordsQuery = null) {
      const url = 'asset'

      await axios.get(url, {
        params: {
          asset_type: this.asset_type,
          fields_of_use: fieldQuery,
          categories: categoryQuery,
          keywords: keywordsQuery,
          project_id: projectQuery
        }, paramsSerializer: {
          indexes: null
        }
      })
        .then(res => res.data)
        .then(data => this.assets = data)
        .catch(err => console.log(err))
    },
    onUpdateFieldQuery(newValue) {
      this.fieldQuery = newValue
      this.getAllAssets(this.fieldQuery,  this.categoryQuery, this.projectQuery)
    },
    onUpdateCategoryQuery(newValue) {
      this.categoryQuery = newValue
      this.getAllAssets(this.fieldQuery, this.categoryQuery, this.projectQuery)
    },
    onUpdateProjectQuery(newValue){
      this.projectQuery = newValue
      this.getAllAssets(this.fieldQuery, this.categoryQuery, this.projectQuery)
    }
  }
}
</script>
