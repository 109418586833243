<template>
    <form @submit.prevent="submitForm">
        <fieldset>

            <div class="form-group">
                <label for="projectName" class="form-label mt-4">Project name</label>
                <input type="text" class="form-control" id="projectName" placeholder="Enter Project Name" v-model="project.name">
                <div class="invalid-feedback">Please enter project name.</div>
            </div>

            <div class="form-group">
                <label for="description" class="form-label mt-4">Project description</label>
                <textarea class="form-control" id="description" rows="3" v-model="project.description"></textarea>
            </div>


            <div class="form-group">
                <label for="webpage_link" class="form-label mt-4">Webpage Link</label>
                <input type="text" class="form-control" id="webpage_link" placeholder="Enter GitLab Link"
                    v-model="project.webpage_link">
            </div>

        </fieldset>
        <button type="submit" class="btn btn-primary mt-4 mb-4">Submit</button>
    </form>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            project: {},
        }
    },
    methods: {
        async submitForm() {
            const project_data = (JSON.parse(JSON.stringify(this.project)))
            const url = 'project'
            await axios.post(url, project_data)
                .then(res => res.data._id)
                .then(id => this.$router.push({ name: 'project-details', params: { id: id } }))
                .catch(err => console.log(err))
        }
    }

}

</script>