<template>
    <div class="col" style="height: 100%;">
        <router-link :to="{ name: 'asset-details', params: { id: asset._id } }" style="text-decoration: none;">

            <div class="card  border-primary mb-2 h-100 w-100" style="min-height: 15rem;" >
                <div class="card-body d-flex flex-column">
                    <div class="p-1" >
                        <!-- title and brief description -->
                        <div class="card-title text-primary  fs-4">
                           <b>{{ asset.name }}</b> 
                        </div>

                    </div>

                    <div class="p-1">
                        <p class="card-text"> 
                           {{ asset.short_description }}                     
                        </p>
                    </div>

                    <div class="mt-auto p-2 d-flex" v-if="asset.keywords">
                        <div class="pe-2 d-flex align-items-center">
                            <span class="text-primary"><i class="bi bi-tags-fill"></i> </span>
                        </div>
                        <div>
                            <small class="text-secondary" v-if="typeof asset.keywords == 'string'">{{ asset.keywords}} </small>
                        <small class="text-secondary" v-else v-for="keyword in asset.keywords" :key="keyword">{{ keyword }}, </small>
                        </div>
                    </div>

                </div>
            </div>
        </router-link>
    </div>
</template>

<script>

export default {
    props: ['asset'],
    beforeMount() {
        this.shortenDescription()
    },
    methods: {
        shortenDescription() {
            var maxLength = 150
            if (this.asset.short_description != undefined) {
                if (this.asset.short_description.length > maxLength){
            this.asset.short_description = this.asset.short_description.substring(0, maxLength)  
            this.asset.short_description = this.asset.short_description.substring(0, Math.min(this.asset.short_description.length, this.asset.short_description.lastIndexOf(" "))) + "..."
            }
            } 
        }
    }
}

</script>