<template>
  <AllAssets :asset_type="asset_type" :subHeaderTitle="subHeaderTitle" :subHeaderSubtitle="subHeaderSubtitle"
    :image="image" />
</template>

<script>
import AllAssets from '../components/AllAssets.vue'

export default {
  components: { AllAssets },
  data() {
    return {
      asset_type: 'Training',
      subHeaderTitle: "Training resources",
      subHeaderSubtitle: "Find training material",
      image: "learning.svg"
    }
  }
}
</script>