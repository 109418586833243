import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)

import router from './router'
import axios from "axios";
import VueAxios from "vue-axios";

app.use(router, VueAxios,axios)
// axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://market.gftinnovation.eu/backend/";
axios.defaults.headers.common["Authorization"] =  "Bearer " + localStorage.getItem("access_token");

import "./assets/main.min.css";
import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";

app.mount('#app')