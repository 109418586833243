<template>
    <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
        <div class="container-xxl">

            <router-link :to="{ name: 'home' }" class="navbar-brand">
                <img v-bind:src="require('../assets/img/GFT_Technologies_logo.svg')" style="max-width: 13rem; height: 100%; background-color: white;"/>
            </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#FAMEnavbar"
                aria-controls="FAMEnavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="FAMEnavbar">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link :to="{ name: 'all-assets' }" class="nav-link fs-5">Assets</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'success-stories' }" class="nav-link fs-5">Success Stories</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'training-resources' }" class="nav-link fs-5">Training Resources</router-link>
                    </li>
                    <li class="nav-item" v-if="authStore.admin">
                        <router-link :to="{ name: 'add-asset' }" class="nav-link fs-5">Add Asset</router-link>
                    </li>
                      <li class="nav-item" v-if="authStore.admin">
                            <router-link :to="{ name: 'add-project' }" class="nav-link fs-5">Add Project</router-link>
                        </li>
                    <li class="nav-item" v-if="!authStore.access_token">
                        <router-link :to="{ name: 'login' }" class="nav-link fs-5">Login</router-link>
                    </li>
                    <li class="nav-item" v-if="authStore.access_token">
                        <a class="nav-link fs-5" @click="logout">Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <router-view />
</template>

<style>
a.router-link-exact-active {
    color: white;
}
</style>

<script>
import { useAuthStore } from '../stores/authStore'

export default {
    setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
    methods: {
        logout() {
            this.authStore.logout()
        }
    }
}
</script>
