<template>
    <div class="container-xxl mt-4 content">
        <h2>logout</h2>
    </div>
</template>

<script>
import { useAuthStore } from '../stores/authStore'

export default {
    setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
    methods: {
        logout() {
            this.authStore.logout()
        }
    },
    created(){
        this.logout()
    }
    
}
</script>