<template>
  <div class="container-xxl content">
    <div class="col m-3 mt-4">
      <ProjectCard :project="project" />
    </div>
    <div class="col m-3 mt-4">
      <h3>Related Assets</h3>
      <CardsList :assets="assets" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ProjectCard from '../components/ProjectCard.vue'
import CardsList from '../components/CardsList.vue'

export default {
  components: { ProjectCard, CardsList },
  props: ['id'],
  created() {
    this.getProject(this.id)
    this.getAllAssets(this.id)

  },
  data() {
    return {
      project: {},
      assets: []
    }
  },
  methods: {
    async getProject(project_id) {
      const url = 'project/' + project_id
      await axios.get(url)
        .then(res => res.data)
        .then(data => this.project = data)
        .catch(err => console.log(err))
    },
    async getAllAssets(projectId = null) {
      const url = 'asset'

      await axios.get(url, {
        params: {
            project_id: projectId
        }, paramsSerializer: {
          indexes: null
        }
      })
        .then(res => res.data)
        .then(data => this.assets = data)
        .catch(err => console.log(err))
    }
  }
}

</script>