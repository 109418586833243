<template>
    <div class="col" style="height: 100%;">
        <div class="card mb-2" >
            <div class="card-body">
                <div class="row" style="height: 100%;">
                    <!-- title and brief description -->
                    <div class="col-8 d-flex">
                        <h4> <b>{{ asset.name }}</b> </h4>
                    </div>
                </div>

                <!-- CARD EXPANDED -->
                <div v-if="showDetails || detail">
                    <div class="row mt-2">
                        <div class="col d-flex ">
                            <p v-if="asset.extended_description">{{ asset.extended_description }}</p>
                            <p v-else> {{ asset.short_description }}</p>
                        </div>
                    </div>

                      <div v-if="asset.project">
                            <hr>
                            <div class="row">
                                <div class="col-sm-5 d-flex ">
                                    <h6 class="">Project</h6>
                                </div>
                                <div class="col-sm-7 d-flex " @click="goToProjDetails(asset.project._id)">
                                   {{ asset.project.name }}
                                </div>
                            </div>
                        </div>

                    <div v-if="asset.owner">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">Owner</h6>
                            </div>
                            <div class="col-sm-7 d-flex ">
                                {{ asset.owner }}
                            </div>
                        </div>
                    </div>

                    <div v-if="asset.release_date">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">Release date</h6>
                            </div>
                            <div class="col-sm-7 d-flex ">
                                {{ asset.release_date }}
                            </div>
                        </div>

                    </div>

                    <div v-if="asset.category">
                            <hr>
                            <div class="row">
                                <div class="col-sm-5 d-flex ">
                                    <h6 class="">Asset Category</h6>
                                </div>
                                <div class="col-sm-7 d-flex">
                                    <div class="d-flex align-items-center">
                                        <span v-if="typeof asset.category == 'string'">
                                            {{ asset.category }}
                                        </span>
                                        <span v-else class="badge rounded-pill bg-primary me-2"
                                            v-for="cat in asset.category" :key="cat">{{
                                                cat }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div v-if="asset.field_of_use">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">Field of use</h6>
                            </div>
                            <div class="col-sm-7 d-flex">
                                <div class="d-flex align-items-center">
                                    <span v-if="typeof asset.field_of_use == 'string'">
                                        {{ asset.field_of_use }}
                                    </span>
                                    <span v-else class="badge rounded-pill bg-primary me-2"
                                        v-for="field in asset.field_of_use" :key="field">{{
                                            field }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="asset.keywords">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">Keywords</h6>
                            </div>
                            <div class="col-sm-7 d-flex ">
                                <div class="d-flex align-items-center">
                                            <span v-if="typeof asset.keywords == 'string'"
                                                class="badge rounded-pill bg-primary me-2">
                                                {{ asset.keywords }}
                                            </span>
                                            <span v-else class="badge rounded-pill bg-primary me-2"
                                                v-for="keyword in asset.keywords" :key="keyword">{{
                                                    keyword }}</span>
                                        </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="asset.asset_webpage_link">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">Web link</h6>
                            </div>
                            <div class="col-sm-7 d-flex ">
                                <a href="#" class="link-primary">{{ asset.asset_webpage_link }}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="asset.gitlab_link">
                        <hr>
                        <div class="row">
                            <div class="col-sm-5 d-flex ">
                                <h6 class="">GitLab link</h6>
                            </div>
                            <div class="col-sm-7 d-flex ">
                                <a href="#" class="link-primary">{{ asset.gitlab_link }}</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['asset', 'detail'],
    methods:{
        goToProjDetails(project_id){
            this.$router.push({ name: 'project-details', params: { id: project_id } })
        }
    }
}

</script>