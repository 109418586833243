<template>
    <fieldset>

        <div class="form-group">
            <label for="username" class="form-label mt-4">Username</label>
            <input type="text" class="form-control" id="username" placeholder="Enter username" v-model="user.username">
            <div class="invalid-feedback">Please enter a username.</div>
        </div>

        <div class="form-group">
            <label for="password" class="form-label mt-4">Password</label>
            <input type="text" class="form-control" id="password" placeholder="Enter password" v-model="user.password">
        </div>

    </fieldset>
    <button type="submit" class="btn btn-primary mt-4 mb-4" @click="login">Login</button>
</template>

<script>
import { useAuthStore } from '../stores/authStore'

export default {
    data() {
        return {
            user: {}
        }
    },
    setup(){
        const authStore = useAuthStore()
        return {authStore}
    },
    methods: {
        login(){
            const body = (JSON.parse(JSON.stringify(this.user)))
            this.authStore.login(body)
        }
    }
}

</script>