<template>
    <footer class="footer bg-secondary" >
        <div class="container">
            <div class="align-items-center d-flex justify-content-center">
                <!-- <div class="col-6 d-flex align-items-center">
                    <div>
                        <img v-bind:src="require('../assets/img/EN_fundedbyEU_VERTICAL_RGB_POS.png')"
                            style="max-width: 8rem; height: 100%" />
                    </div>
                    <div>
                        <p class="text-muted">
                            The FAME project has received funding from the European Union’s
                            Horizon research and innovation programme under Grant Agreement
                            no 101092639
                        </p>
                    </div>
                </div> -->

                <div class="d-flex flex-row align-items-center">
                    <a class="p-2" href="https://twitter.com/gft_en">
                        <i class="bi bi-twitter h3 me-2 text-info"></i>
                    </a>
                     <a class="p-2" href="https://www.instagram.com/gft_italia/">
                            <i class="bi bi-instagram h3 me-2 text-info"></i>
                    </a>
                    <a class="p-2" href="https://www.facebook.com/GFTGroup">
                                <i class="bi bi-facebook h3 me-2 text-info"></i>
                        </a>
                    <a class="p-2" href="https://www.linkedin.com/company/gft-technologies/">
                        <i class="bi bi-linkedin h3 me-2 text-info"></i>
                    </a>
                    <a class="p-2" href="https://www.gft.com/it/it">
                        <img :src="require('../assets/img/gft.png')" style="height: 1.9rem;"/>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>



